.App {
  text-align: center;
  overflow: hidden;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
	width: 100vw;
	height: 100vh;
	background-image: linear-gradient(to right top, #2a4e9c, #2365b2, #1d7cc6, #1d93d9, #27aaea);
	display: flex;
	justify-content: center;
	align-items: center;
	
}


.contenedor{
	display: inline-block;
	padding:5px;
	box-sizing: border-box;
	width:500px;
	height:500px;
  object-fit: contain;
  }

.imgLoading{
	object-fit: contain;
	width:100%;
	height:100%;
	animation: blur-text 4s infinite;
  }

#name-label{
  color:black
}
#lastname-label{
  color:black
}
#phone-label{
  color:black
}
#email-label{
  color:black
}

@keyframes blur-text {
	0% {filter: blur(0px);}
	100% {filter: blur(4px);}
}

